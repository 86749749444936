define("discourse/plugins/discourse-cakeday/discourse/adapters/anniversary", ["exports", "discourse/plugins/discourse-cakeday/discourse/adapters/cakeday"], function (_exports, _cakeday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _cakeday.default;
  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/adapters/birthday", ["exports", "discourse/plugins/discourse-cakeday/discourse/adapters/cakeday"], function (_exports, _cakeday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _cakeday.default;
  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/adapters/cakeday", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    basePath() {
      return "/cakeday/";
    }

  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/components/emoji-images", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/lib/text", "I18n"], function (_exports, _component, _decorators, _text, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("list"), _dec2 = (0, _decorators.default)("title"), (_obj = {
    classNames: ["emoji-images"],

    emojiHTML(list) {
      return list.split("|").map(et => (0, _text.emojiUnescape)(":".concat(et, ":"), {
        skipTitle: true
      }));
    },

    titleText(title) {
      return _I18n.default.t(title);
    }

  }, (_applyDecoratedDescriptor(_obj, "emojiHTML", [_dec], Object.getOwnPropertyDescriptor(_obj, "emojiHTML"), _obj), _applyDecoratedDescriptor(_obj, "titleText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "titleText"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/connectors/user-card-post-names/user-card-cakeday", ["exports", "discourse/plugins/discourse-cakeday/discourse/lib/cakeday"], function (_exports, _cakeday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    setupComponent(args, component) {
      component.set("isCakeday", (0, _cakeday.cakeday)(args.user.get("created_at")));
      component.set("isUserBirthday", (0, _cakeday.cakedayBirthday)(args.user.get("date_of_birth")));
      component.set("cakedayTitle", (0, _cakeday.cakedayTitle)(args.user, this.currentUser));
      component.set("cakedayBirthdayTitle", (0, _cakeday.cakedayBirthdayTitle)(args.user, this.currentUser));
    }

  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/connectors/user-custom-preferences/user-date-of-birth-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    setupComponent(args, component) {
      const months = moment.months().map((month, index) => {
        return {
          name: month,
          value: index + 1
        };
      });
      const days = Array.from(Array(31).keys()).map(x => (x + 1).toString());
      const dateOfBirth = args.model.get("date_of_birth");
      const userBirthdayMonth = dateOfBirth ? moment(dateOfBirth, "YYYY-MM-DD").month() + 1 : null;
      const userBirthdayDay = dateOfBirth ? moment(dateOfBirth, "YYYY-MM-DD").date().toString() : null;
      component.setProperties({
        months,
        days,
        userBirthdayMonth,
        userBirthdayDay
      });

      const updateBirthday = function () {
        let date = "";

        if (component.userBirthdayMonth && component.userBirthdayDay) {
          date = "1904-".concat(component.userBirthdayMonth, "-").concat(component.userBirthdayDay);
        }

        args.model.set("date_of_birth", date);
      };

      component.addObserver("userBirthdayMonth", updateBirthday);
      component.addObserver("userBirthdayDay", updateBirthday);
    }

  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/connectors/user-post-names/user-cakeday", ["exports", "discourse/plugins/discourse-cakeday/discourse/lib/cakeday"], function (_exports, _cakeday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    setupComponent(args, component) {
      component.set("isCakeday", (0, _cakeday.cakeday)(args.model.get("created_at")));
      component.set("isUserBirthday", (0, _cakeday.cakedayBirthday)(args.model.get("date_of_birth")));
    }

  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-anniversaries-all", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _controller.default.extend((_obj = {
    queryParams: ["month"],
    month: moment().month() + 1,

    months() {
      return moment.months().map((month, index) => {
        return {
          name: month,
          value: index + 1
        };
      });
    },

    actions: {
      loadMore() {
        this.get("model").loadMore();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "months", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "months"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-anniversaries-today", ["exports", "@ember/controller", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _controller.default.extend((_obj = {
    title() {
      return _I18n.default.t("anniversaries.today.title", {
        date: moment().format(_I18n.default.t("dates.full_no_year_no_time"))
      });
    },

    actions: {
      loadMore() {
        this.get("model").loadMore();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-anniversaries-tomorrow", ["exports", "@ember/controller", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _controller.default.extend((_obj = {
    title() {
      return _I18n.default.t("anniversaries.today.title", {
        date: moment().add(1, "day").format(_I18n.default.t("dates.full_no_year_no_time"))
      });
    },

    actions: {
      loadMore() {
        this.get("model").loadMore();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-anniversaries-upcoming", ["exports", "@ember/controller", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _controller.default.extend((_obj = {
    title() {
      const date = moment();

      const dateFormat = _I18n.default.t("dates.full_no_year_no_time");

      return _I18n.default.t("anniversaries.upcoming.title", {
        start_date: date.add(2, "days").format(dateFormat),
        end_date: date.add(7, "days").format(dateFormat)
      });
    },

    actions: {
      loadMore() {
        this.get("model").loadMore();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-birthdays-all", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _controller.default.extend((_obj = {
    queryParams: ["month"],
    month: moment().month() + 1,

    months() {
      return moment.months().map((month, index) => {
        return {
          name: month,
          value: index + 1
        };
      });
    },

    actions: {
      loadMore() {
        this.get("model").loadMore();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "months", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "months"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-birthdays-today", ["exports", "@ember/controller", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _controller.default.extend((_obj = {
    title() {
      return _I18n.default.t("birthdays.today.title", {
        date: moment().format(_I18n.default.t("dates.full_no_year_no_time"))
      });
    },

    actions: {
      loadMore() {
        this.get("model").loadMore();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-birthdays-tomorrow", ["exports", "@ember/controller", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _controller.default.extend((_obj = {
    title() {
      return _I18n.default.t("birthdays.today.title", {
        date: moment().add(1, "day").format(_I18n.default.t("dates.full_no_year_no_time"))
      });
    },

    actions: {
      loadMore() {
        this.get("model").loadMore();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-birthdays-upcoming", ["exports", "@ember/controller", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _controller.default.extend((_obj = {
    title() {
      const date = moment();

      const dateFormat = _I18n.default.t("dates.full_no_year_no_time");

      return _I18n.default.t("birthdays.upcoming.title", {
        start_date: date.add(2, "days").format(dateFormat),
        end_date: date.add(7, "days").format(dateFormat)
      });
    },

    actions: {
      loadMore() {
        this.get("model").loadMore();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    cakedayEnabled: (0, _computed.alias)("siteSettings.cakeday_enabled"),
    cakedayBirthdayEnabled: (0, _computed.alias)("siteSettings.cakeday_birthday_enabled")
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/discourse-cakeday-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.route("cakeday", {
      path: "/cakeday",
      resetNamespace: true
    }, function () {
      this.route("birthdays", {
        path: "/birthdays"
      }, function () {
        this.route("today", {
          path: "/today"
        });
        this.route("tomorrow", {
          path: "/tomorrow"
        });
        this.route("upcoming", {
          path: "/upcoming"
        });
        this.route("all", {
          path: "/all"
        });
      });
      this.route("anniversaries", {
        path: "/anniversaries"
      }, function () {
        this.route("today", {
          path: "/today"
        });
        this.route("tomorrow", {
          path: "/tomorrow"
        });
        this.route("upcoming", {
          path: "/upcoming"
        });
        this.route("all", {
          path: "/all"
        });
      });
    });
  }
});
define("discourse/plugins/discourse-cakeday/discourse/initializers/cakeday", ["exports", "I18n", "discourse-common/utils/decorators", "discourse/lib/plugin-api", "discourse/plugins/discourse-cakeday/discourse/lib/cakeday", "discourse-common/lib/helpers", "@ember/utils"], function (_exports, _I18n, _decorators, _pluginApi, _cakeday, _helpers, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function initializeCakeday(api) {
    var _dec, _dec2, _dec3, _obj, _dec4, _dec5, _obj2, _dec6, _dec7, _obj3;

    const currentUser = api.getCurrentUser();

    if (!currentUser) {
      return;
    }

    const store = api.container.lookup("service:store");
    store.addPluralization("anniversary", "anniversaries");
    api.modifyClass("controller:preferences", (_dec = (0, _decorators.observes)("userBirthdayMonth", "userBirthdayDay"), _dec2 = (0, _decorators.default)("model.date_of_birth"), _dec3 = (0, _decorators.default)("model.date_of_birth"), (_obj = {
      pluginId: "discourse-cakeday",
      days: [...Array(32).keys()].splice(1),

      months() {
        return moment.months().map((month, index) => {
          return {
            name: month,
            value: index + 1
          };
        });
      },

      _setUserDateOfBirth() {
        const userBirthdayMonth = this.get("userBirthdayMonth");
        const userBirthdayDay = this.get("userBirthdayDay");
        const user = this.get("model");
        let date = "";

        if (userBirthdayMonth !== "" && userBirthdayDay !== "") {
          date = "1904-".concat(this.get("userBirthdayMonth"), "-").concat(this.get("userBirthdayDay"));
        }

        user.set("date_of_birth", date);
      },

      userBirthdayMonth(dateOfBirth) {
        return moment(dateOfBirth, "YYYY-MM-DD").month() + 1;
      },

      userBirthdayDay(dateOfBirth) {
        return moment(dateOfBirth, "YYYY-MM-DD").date();
      }

    }, (_applyDecoratedDescriptor(_obj, "months", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "months"), _obj), _applyDecoratedDescriptor(_obj, "_setUserDateOfBirth", [_dec], Object.getOwnPropertyDescriptor(_obj, "_setUserDateOfBirth"), _obj), _applyDecoratedDescriptor(_obj, "userBirthdayMonth", [_dec2], Object.getOwnPropertyDescriptor(_obj, "userBirthdayMonth"), _obj), _applyDecoratedDescriptor(_obj, "userBirthdayDay", [_dec3], Object.getOwnPropertyDescriptor(_obj, "userBirthdayDay"), _obj)), _obj)));
    api.modifyClass("controller:user-card", (_dec4 = (0, _decorators.default)("model.created_at"), _dec5 = (0, _decorators.default)("model.date_of_birth"), (_obj2 = {
      pluginId: "discourse-cakeday",

      isCakeday(createdAt) {
        return (0, _cakeday.cakeday)(createdAt);
      },

      isUserBirthday(dateOfBirth) {
        return (0, _cakeday.cakedayBirthday)(dateOfBirth);
      }

    }, (_applyDecoratedDescriptor(_obj2, "isCakeday", [_dec4], Object.getOwnPropertyDescriptor(_obj2, "isCakeday"), _obj2), _applyDecoratedDescriptor(_obj2, "isUserBirthday", [_dec5], Object.getOwnPropertyDescriptor(_obj2, "isUserBirthday"), _obj2)), _obj2)));
    api.modifyClass("controller:user", (_dec6 = (0, _decorators.default)("model.created_at"), _dec7 = (0, _decorators.default)("model.date_of_birth"), (_obj3 = {
      pluginId: "discourse-cakeday",

      isCakeday(createdAt) {
        return (0, _cakeday.cakeday)(createdAt);
      },

      isUserBirthday(dateOfBirth) {
        return (0, _cakeday.cakedayBirthday)(dateOfBirth);
      }

    }, (_applyDecoratedDescriptor(_obj3, "isCakeday", [_dec6], Object.getOwnPropertyDescriptor(_obj3, "isCakeday"), _obj3), _applyDecoratedDescriptor(_obj3, "isUserBirthday", [_dec7], Object.getOwnPropertyDescriptor(_obj3, "isUserBirthday"), _obj3)), _obj3)));
    const siteSettings = api.container.lookup("site-settings:main");
    const emojiEnabled = siteSettings.enable_emoji;
    const cakedayEnabled = siteSettings.cakeday_enabled;
    const cakedayBirthdayEnabled = siteSettings.cakeday_birthday_enabled;

    if (cakedayEnabled) {
      api.includePostAttributes("user_created_at");
      api.includePostAttributes("user_date_of_birth");
      api.addPosterIcon((cfs, attrs) => {
        const createdAt = attrs.user_created_at;

        if (!(0, _utils.isEmpty)(createdAt) && (0, _cakeday.isSameDay)(createdAt, {
          anniversary: true
        })) {
          let result = {};

          if (emojiEnabled) {
            result.emoji = siteSettings.cakeday_emoji;
          } else {
            result.icon = "birthday-cake";
          }

          if (currentUser && attrs.user_id === currentUser.get("id")) {
            result.title = _I18n.default.t("user.anniversary.user_title");
          } else {
            result.title = _I18n.default.t("user.anniversary.title");
          }

          result.emojiTitle = false;
          return result;
        }
      });
    }

    if (cakedayBirthdayEnabled) {
      api.addPosterIcon((cfs, attrs) => {
        const dob = attrs.user_date_of_birth;

        if (!(0, _utils.isEmpty)(dob) && (0, _cakeday.isSameDay)(dob)) {
          let result = {};

          if (emojiEnabled) {
            result.emoji = siteSettings.cakeday_birthday_emoji;
          } else {
            result.icon = "birthday-cake";
          }

          if (currentUser && attrs.user_id === currentUser.get("id")) {
            result.title = _I18n.default.t("user.date_of_birth.user_title");
          } else {
            result.title = _I18n.default.t("user.date_of_birth.title");
          }

          result.emojiTitle = false;
          return result;
        }
      });
    }

    if (cakedayEnabled || cakedayBirthdayEnabled) {
      (0, _helpers.registerUnbound)("cakeday-date", function (val, params) {
        const date = moment(val);

        if (params.isBirthday) {
          return date.format(_I18n.default.t("dates.full_no_year_no_time"));
        } else {
          return date.format(_I18n.default.t("dates.full_with_year_no_time"));
        }
      });
      api.decorateWidget("hamburger-menu:generalLinks", () => {
        let route;

        if (cakedayEnabled) {
          route = "cakeday.anniversaries.today";
        } else if (cakedayBirthdayEnabled) {
          route = "cakeday.birthdays.today";
        }

        return {
          route,
          label: "cakeday.title",
          className: "cakeday-link"
        };
      });
    }
  }

  var _default = {
    name: "cakeday",

    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => initializeCakeday(api));
    }

  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/lib/cakeday", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cakeday = cakeday;
  _exports.cakedayBirthday = cakedayBirthday;
  _exports.cakedayBirthdayTitle = cakedayBirthdayTitle;
  _exports.cakedayTitle = cakedayTitle;
  _exports.isSameDay = isSameDay;

  function isSameDay(date, opts) {
    let formatString = "YYYY";
    const current = moment();
    const currentDate = moment(date);

    if (opts && opts.anniversary) {
      if (current.format(formatString) <= currentDate.format(formatString)) {
        return false;
      }
    }

    formatString = "MMDD";
    return current.format(formatString) === currentDate.format(formatString);
  }

  function cakeday(createdAt) {
    if ((0, _utils.isEmpty)(createdAt)) {
      return false;
    }

    return isSameDay(createdAt, {
      anniversary: true
    });
  }

  function cakedayBirthday(dateOfBirth) {
    if ((0, _utils.isEmpty)(dateOfBirth)) {
      return false;
    }

    return isSameDay(dateOfBirth);
  }

  function cakedayTitle(user, currentUser) {
    if (isSameUser(user, currentUser)) {
      return "user.anniversary.user_title";
    } else {
      return "user.anniversary.title";
    }
  }

  function cakedayBirthdayTitle(user, currentUser) {
    if (isSameUser(user, currentUser)) {
      return "user.date_of_birth.user_title";
    } else {
      return "user.date_of_birth.title";
    }
  }

  function isSameUser(user, currentUser) {
    if (!currentUser) {
      return false;
    }

    return user.get("id") === currentUser.get("id");
  }
});
define("discourse/plugins/discourse-cakeday/discourse/routes/build-cakeday-route", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (storeName, filter) => {
    return _discourse.default.extend({
      model(params) {
        params.timezone_offset = new Date().getTimezoneOffset();

        if (filter) {
          params.filter = filter;
        }

        return this.store.find(storeName, params);
      }

    });
  };

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-anniversaries-all", ["exports", "discourse/plugins/discourse-cakeday/discourse/routes/build-cakeday-route"], function (_exports, _buildCakedayRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildCakedayRoute.default)("anniversary").extend({
    queryParams: {
      month: {
        refreshModel: true
      }
    },
    refreshQueryWithoutTransition: true
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-anniversaries-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel() {
      this.replaceWith("cakeday.anniversaries.today");
    }

  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-anniversaries-today", ["exports", "discourse/plugins/discourse-cakeday/discourse/routes/build-cakeday-route"], function (_exports, _buildCakedayRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildCakedayRoute.default)("anniversary", "today");

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-anniversaries-tomorrow", ["exports", "discourse/plugins/discourse-cakeday/discourse/routes/build-cakeday-route"], function (_exports, _buildCakedayRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildCakedayRoute.default)("anniversary", "tomorrow");

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-anniversaries-upcoming", ["exports", "discourse/plugins/discourse-cakeday/discourse/routes/build-cakeday-route"], function (_exports, _buildCakedayRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildCakedayRoute.default)("anniversary", "upcoming");

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-anniversaries", ["exports", "I18n", "discourse/routes/discourse"], function (_exports, _I18n, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel() {
      if (!this.siteSettings.cakeday_enabled) {
        this.transitionTo("unknown", window.location.pathname.replace(/^\//, ""));
      }
    },

    titleToken() {
      return _I18n.default.t("anniversaries.title");
    }

  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-birthdays-all", ["exports", "discourse/plugins/discourse-cakeday/discourse/routes/build-cakeday-route"], function (_exports, _buildCakedayRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildCakedayRoute.default)("birthday").extend({
    queryParams: {
      month: {
        refreshModel: true
      }
    },
    refreshQueryWithoutTransition: true
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-birthdays-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel() {
      this.replaceWith("cakeday.birthdays.today");
    }

  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-birthdays-today", ["exports", "discourse/plugins/discourse-cakeday/discourse/routes/build-cakeday-route"], function (_exports, _buildCakedayRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildCakedayRoute.default)("birthday", "today");

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-birthdays-tomorrow", ["exports", "discourse/plugins/discourse-cakeday/discourse/routes/build-cakeday-route"], function (_exports, _buildCakedayRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildCakedayRoute.default)("birthday", "tomorrow");

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-birthdays-upcoming", ["exports", "discourse/plugins/discourse-cakeday/discourse/routes/build-cakeday-route"], function (_exports, _buildCakedayRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildCakedayRoute.default)("birthday", "upcoming");

  _exports.default = _default;
});
define("discourse/plugins/discourse-cakeday/discourse/routes/cakeday-birthdays", ["exports", "I18n", "discourse/routes/discourse"], function (_exports, _I18n, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel() {
      if (!this.siteSettings.cakeday_birthday_enabled) {
        this.transitionTo("unknown", window.location.pathname.replace(/^\//, ""));
      }
    },

    titleToken() {
      return _I18n.default.t("birthdays.title");
    }

  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/cakeday"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[14,0,\"container cakeday\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"nav-pills\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"      \"],[10,\"li\"],[14,0,\"nav-item-anniversaries\"],[12],[1,\"\\n\"],[6,[39,2],null,[[\"route\"],[\"cakeday.anniversaries\"]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,3],[\"anniversaries.title\"],null]],[1,\"\\n\"]],[]]]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,4],[[[1,\"      \"],[10,\"li\"],[14,0,\"nav-item-birthdays\"],[12],[1,\"\\n\"],[6,[39,2],null,[[\"route\"],[\"cakeday.birthdays\"]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,3],[\"birthdays.title\"],null]],[1,\"\\n\"]],[]]]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"cakedayEnabled\",\"link-to\",\"i18n\",\"cakedayBirthdayEnabled\",\"component\",\"-outlet\"]]","moduleName":"javascripts/discourse/templates/cakeday","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/anniversaries"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[14,0,\"anniversaries\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"nav-pills\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\"],[\"cakeday.anniversaries.today\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"cakeday.today\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\"],[\"cakeday.anniversaries.tomorrow\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"cakeday.tomorrow\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\"],[\"cakeday.anniversaries.upcoming\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"cakeday.upcoming\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\"],[\"cakeday.anniversaries.all\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"cakeday.all\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"i18n\",\"component\",\"-outlet\"]]","moduleName":"javascripts/discourse/templates/cakeday/anniversaries","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/anniversaries/all"] = Ember.HTMLBars.template({"id":null,"block":"[[[6,[39,0],null,[[\"selector\",\"action\"],[\".user-info\",[28,[37,1],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"cakeday-months\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"cakeday-header\"],[12],[1,[28,[35,2],[\"anniversaries.month.title\"],null]],[13],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"content\",\"value\",\"valueAttribute\",\"none\"],[[33,4],[33,5],\"value\",\"cakeday.none\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[6,[39,6],null,[[\"condition\"],[[33,7,[\"loading\"]]]],[[\"default\"],[[[[6,[39,8],null,[[\"users\"],[[33,7]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,2],[\"anniversaries.month.empty\"],null]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n  \"],[1,[28,[35,6],null,[[\"condition\"],[[33,7,[\"loadingMore\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"load-more\",\"action\",\"i18n\",\"combo-box\",\"months\",\"month\",\"conditional-loading-spinner\",\"model\",\"user-info-list\"]]","moduleName":"javascripts/discourse/templates/cakeday/anniversaries/all","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/anniversaries/today"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"h2\"],[14,0,\"cakeday-header\"],[12],[1,[34,0]],[13],[1,\"\\n\\n\"],[6,[39,1],null,[[\"selector\",\"action\"],[\".user-info\",[28,[37,2],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[6,[39,3],null,[[\"condition\"],[[33,4,[\"loading\"]]]],[[\"default\"],[[[[6,[39,5],null,[[\"users\"],[[33,4]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,6],[\"anniversaries.today.empty\"],null]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"condition\"],[[33,4,[\"loadingMore\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"title\",\"load-more\",\"action\",\"conditional-loading-spinner\",\"model\",\"user-info-list\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/cakeday/anniversaries/today","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/anniversaries/tomorrow"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"h2\"],[14,0,\"cakeday-header\"],[12],[1,[34,0]],[13],[1,\"\\n\\n\"],[6,[39,1],null,[[\"selector\",\"action\"],[\".user-info\",[28,[37,2],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[6,[39,3],null,[[\"condition\"],[[33,4,[\"loading\"]]]],[[\"default\"],[[[[6,[39,5],null,[[\"users\"],[[33,4]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,6],[\"anniversaries.tomorrow.empty\"],null]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"condition\"],[[33,4,[\"loadingMore\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"title\",\"load-more\",\"action\",\"conditional-loading-spinner\",\"model\",\"user-info-list\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/cakeday/anniversaries/tomorrow","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/anniversaries/upcoming"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"h2\"],[14,0,\"cakeday-header\"],[12],[1,[34,0]],[13],[1,\"\\n\\n\"],[6,[39,1],null,[[\"selector\",\"action\"],[\".user-info\",[28,[37,2],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[6,[39,3],null,[[\"condition\"],[[33,4,[\"loading\"]]]],[[\"default\"],[[[[6,[39,5],null,[[\"users\"],[[33,4]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,6],[\"anniversaries.upcoming.empty\"],null]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"condition\"],[[33,4,[\"loadingMore\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"title\",\"load-more\",\"action\",\"conditional-loading-spinner\",\"model\",\"user-info-list\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/cakeday/anniversaries/upcoming","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/birthdays"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[14,0,\"birthdays\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"nav-pills\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\"],[\"cakeday.birthdays.today\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"cakeday.today\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\"],[\"cakeday.birthdays.tomorrow\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"cakeday.tomorrow\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\"],[\"cakeday.birthdays.upcoming\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"cakeday.upcoming\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\"],[\"cakeday.birthdays.all\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"cakeday.all\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"i18n\",\"component\",\"-outlet\"]]","moduleName":"javascripts/discourse/templates/cakeday/birthdays","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/birthdays/all"] = Ember.HTMLBars.template({"id":null,"block":"[[[6,[39,0],null,[[\"selector\",\"action\"],[\".user-info\",[28,[37,1],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"cakeday-months\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"cakeday-header\"],[12],[1,[28,[35,2],[\"birthdays.month.title\"],null]],[13],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"content\",\"value\",\"valueAttribute\",\"none\"],[[33,4],[33,5],\"value\",\"cakeday.none\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[6,[39,6],null,[[\"condition\"],[[33,7,[\"loading\"]]]],[[\"default\"],[[[[6,[39,8],null,[[\"users\",\"isBirthday\"],[[33,7],true]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,2],[\"birthdays.month.empty\"],null]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n  \"],[1,[28,[35,6],null,[[\"condition\"],[[33,7,[\"loadingMore\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"load-more\",\"action\",\"i18n\",\"combo-box\",\"months\",\"month\",\"conditional-loading-spinner\",\"model\",\"user-info-list\"]]","moduleName":"javascripts/discourse/templates/cakeday/birthdays/all","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/birthdays/today"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"h2\"],[14,0,\"cakeday-header\"],[12],[1,[34,0]],[13],[1,\"\\n\\n\"],[6,[39,1],null,[[\"selector\",\"action\"],[\".user-info\",[28,[37,2],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[6,[39,3],null,[[\"condition\"],[[33,4,[\"loading\"]]]],[[\"default\"],[[[[6,[39,5],null,[[\"users\",\"isBirthday\"],[[33,4],true]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,6],[\"birthdays.today.empty\"],null]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"condition\"],[[33,4,[\"loadingMore\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"title\",\"load-more\",\"action\",\"conditional-loading-spinner\",\"model\",\"user-info-list\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/cakeday/birthdays/today","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/birthdays/tomorrow"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"h2\"],[14,0,\"cakeday-header\"],[12],[1,[34,0]],[13],[1,\"\\n\\n\"],[6,[39,1],null,[[\"selector\",\"action\"],[\".user-info\",[28,[37,2],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[6,[39,3],null,[[\"condition\"],[[33,4,[\"loading\"]]]],[[\"default\"],[[[[6,[39,5],null,[[\"users\",\"isBirthday\"],[[33,4],true]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,6],[\"birthdays.tomorrow.empty\"],null]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"condition\"],[[33,4,[\"loadingMore\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"title\",\"load-more\",\"action\",\"conditional-loading-spinner\",\"model\",\"user-info-list\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/cakeday/birthdays/tomorrow","isStrictMode":false});
Ember.TEMPLATES["javascripts/cakeday/birthdays/upcoming"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"h2\"],[14,0,\"cakeday-header\"],[12],[1,[34,0]],[13],[1,\"\\n\\n\"],[6,[39,1],null,[[\"selector\",\"action\"],[\".user-info\",[28,[37,2],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[6,[39,3],null,[[\"condition\"],[[33,4,[\"loading\"]]]],[[\"default\"],[[[[6,[39,5],null,[[\"users\",\"isBirthday\"],[[33,4],true]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,6],[\"birthdays.upcoming.empty\"],null]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"condition\"],[[33,4,[\"loadingMore\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"title\",\"load-more\",\"action\",\"conditional-loading-spinner\",\"model\",\"user-info-list\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/cakeday/birthdays/upcoming","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/emoji-images"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"enable_emoji\"]],[[[1,\"  \"],[10,0],[15,\"title\",[36,2]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[1,\"      \"],[1,[28,[35,6],[[30,1]],null]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,7],[\"birthday-cake\"],[[\"title\"],[[33,2]]]]],[1,\"\\n\"]],[]]]],[\"html\"],false,[\"if\",\"siteSettings\",\"titleText\",\"each\",\"-track-array\",\"emojiHTML\",\"html-safe\",\"d-icon\"]]","moduleName":"javascripts/discourse/templates/components/emoji-images","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/user-info-list"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"ul\"],[14,0,\"user-info-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[14,0,\"user-info-item\"],[12],[1,\"\\n\"],[6,[39,3],null,[[\"user\"],[[30,1]]],[[\"default\"],[[[[1,\"        \"],[10,0],[12],[1,[28,[35,4],[[30,1,[\"cakeday_date\"]]],[[\"isBirthday\"],[[33,5]]]]],[13],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[1]],[[[1,\"    \"],[10,0],[14,0,\"user-info-empty-message\"],[12],[10,\"h4\"],[12],[18,2,null],[13],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"user\",\"&default\"],false,[\"each\",\"-track-array\",\"users\",\"user-info\",\"cakeday-date\",\"isBirthday\",\"yield\"]]","moduleName":"javascripts/discourse/templates/components/user-info-list","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/user-card-post-names/user-card-cakeday"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"cakeday_birthday_enabled\"]],[[[41,[33,2],[[[1,\"    \"],[1,[28,[35,3],null,[[\"list\",\"title\"],[[33,1,[\"cakeday_birthday_emoji\"]],[33,4]]]]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[41,[33,1,[\"cakeday_enabled\"]],[[[41,[33,5],[[[1,\"    \"],[1,[28,[35,3],null,[[\"list\",\"title\"],[[33,1,[\"cakeday_emoji\"]],[33,6]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"siteSettings\",\"isUserBirthday\",\"emoji-images\",\"cakedayBirthdayTitle\",\"isCakeday\",\"cakedayTitle\"]]","moduleName":"javascripts/discourse/templates/connectors/user-card-post-names/user-card-cakeday","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/user-custom-preferences/user-date-of-birth-input"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"cakeday_birthday_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"user.date_of_birth.label\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"content\",\"value\",\"valueAttribute\",\"valueProperty\",\"none\",\"options\",\"onChange\"],[[33,4],[33,5],\"value\",\"value\",\"cakeday.none\",[28,[37,6],null,[[\"clearable\",\"autoInsertNoneItem\"],[true,false]]],[28,[37,7],[[30,0],[28,[37,8],[[33,5]],null]],null]]]]],[1,\"\\n\\n      \"],[1,[28,[35,3],null,[[\"content\",\"value\",\"valueProperty\",\"nameProperty\",\"none\",\"options\",\"onChange\"],[[33,9],[33,10],null,null,\"cakeday.none\",[28,[37,6],null,[[\"clearable\",\"autoInsertNoneItem\"],[true,false]]],[28,[37,7],[[30,0],[28,[37,8],[[33,10]],null]],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"siteSettings\",\"i18n\",\"combo-box\",\"months\",\"userBirthdayMonth\",\"hash\",\"action\",\"mut\",\"days\",\"userBirthdayDay\"]]","moduleName":"javascripts/discourse/templates/connectors/user-custom-preferences/user-date-of-birth-input","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/user-post-names/user-cakeday"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"cakeday_birthday_enabled\"]],[[[41,[33,2],[[[1,\"    \"],[1,[28,[35,3],null,[[\"list\",\"title\"],[[33,1,[\"cakeday_birthday_emoji\"]],\"birthdays.title\"]]]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[41,[33,1,[\"cakeday_enabled\"]],[[[41,[33,4],[[[1,\"    \"],[1,[28,[35,3],null,[[\"list\",\"title\"],[[33,1,[\"cakeday_emoji\"]],\"anniversaries.title\"]]]],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"siteSettings\",\"isUserBirthday\",\"emoji-images\",\"isCakeday\"]]","moduleName":"javascripts/discourse/templates/connectors/user-post-names/user-cakeday","isStrictMode":false});

